<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import IndexService from "@/MainServices/IndexService.js";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
// import Skeleton from "@/view/front/components/skeleton";

import Multiselect from "vue-multiselect";
import moment from "moment";

/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      cities: [],
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      success: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
    this.getCities();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },

    getCities() {
      IndexService.getCities().then((res) => {
        res.data.map((el) => {
          this.cities.push({
            id: el.id,
            value: el.city_name,
          });
        });
      });
    },

    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          console.log(res);
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- <section class="section_ch_p bg-light d-table w-100 mt-15 bg-white">
      <div class="bg-white container">
        <div class="row change-direction-mb">
          <div class="col-lg-7  col-6 bg-white py-20 pl-0">
            <h1 class="simple-heading2 h m_title">
              <span>Career</span> <b>Resources</b>
            </h1>
            <div class="p_about2">
              Whether you're looking to discover tips on networking or want to
              know how to bestprepare for an interview, this is the place for
              career advice and tips.
            </div>
          </div>
          <div class="col-lg-5 col-6 bg-white py-20 pl-0 img-mb">
           
              <img
          
                src="images/carer_resorses/CareerResources.png"
                alt=""
                style="width: 100%;padding: 10%;"
              />
          
           </div>
        </div>
      </div>
  </section> -->

    <!-- Hero Start -->
    <section class="section_ch_p bg-white d-table w-100 mt-12">
      <div class="bg-light">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-5 pt-10 display_img pb-0">
            <img
              src="images/carer_resorses/CareerResources.png"
              alt=""
              class="float-right image_on_mb"
              style="width: 100%; padding: 10%"
            />
          </div>
          <div class="col-lg-7 bg-light py-lg-20 pl-0 row_fix_mb">
            <h1 class="simple-heading2 h m_title m_title_mb heading_on_imb">
              <span>Career</span> <br class="display_img" />
              <b>Resources</b>
            </h1>
            <div class="p_about2 p_about2_mb">
              Whether you're looking to discover tips on networking or want to
              know how to bestprepare for an interview, this is the place for
              career advice and tips.
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container"> -->
      <div class="row bg-white">
        <div
          class="green_heding_crear container pt-4 pb-4 green_heding_crear_imb"
          style="margin: 0 auto"
        >
          Looking for work?
        </div>
        <div
          class="col-lg-7 bg-white"
          style="max-height: 100px !important"
        ></div>
        <div class="col-lg-5 p-10 carear_img_style hide_img">
          <img
            src="images/carer_resorses/CareerResources.png"
            alt=""
            style="width: 100%; padding: 10%"
          />
        </div>
      </div>
      <!-- </div>      -->
      <div class="container">
        <div class="row">
          <div
            class="col-lg-8 col-md-8 pl-0 col_fix_imb"
            style="margin-top: -10%"
          >
            <!-- <hr> -->
            <div class="row bg-white">
              <div class="col-lg-12 row px-lg-9">
                <!-- <div class="col-lg-12 small_heading">Grab quick bites..!</div> -->
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/career-paths" class="side-nav-link-ref">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/CareerPaths.png"
                        alt="Lights"
                        style="width: 100%"
                      />

                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/career-paths"
                            class="side-nav-link-ref text-muted"
                            >Career paths</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Discover different careerpaths and how to choose
                          theright one
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/networking-skills">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/Networkingskills.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/networking-skills"
                            class="side-nav-link-ref text-muted"
                            >Networking Skills</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Learn about the importance ofnetworking and how
                          toimprove your networking skills
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/future-jobs">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/futurejobs.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/future-jobs"
                            class="side-nav-link-ref text-muted"
                            >Future Jobs</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Discover the ten jobs in demand for the nxt ten year
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                class="
                  col-lg-12
                  mb-5
                  green_heding_crear
                  green_heding_crear_imb
                  green_heding_crear_imb_2
                "
              >
                Application Process
              </div>
              <div class="col-lg-12 row px-lg-9">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/cv-advice">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/Coverletteradvice.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/cv-advice"
                            class="side-nav-link-ref text-muted"
                            >CV advice</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Learn about how to write agood CV and what makes agood
                          CV stand out
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/cover-letter-advice">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/CVAdvice.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/cover-letter-advice"
                            class="side-nav-link-ref text-muted"
                            >Cover Letter Advice</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Discover the ten jobs in demand for the nxt ten year
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/interview-preparation">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/InterviewPreparation.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/interview-preparation"
                            class="side-nav-link-ref text-muted"
                            >Interview Preparation</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Insights on how to best preparefor a job interview and
                          standout from the crowd
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                class="
                  col-lg-12
                  mb-5
                  green_heding_crear
                  green_heding_crear_imb
                  green_heding_crear_imb_2
                "
              >
                Career Development
              </div>
              <div class="col-lg-12 row px-lg-9">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/starting-a-new-job">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/CareerPaths.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/starting-a-new-job"
                            class="side-nav-link-ref text-muted"
                            >Starting A New Job</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          How to successfully integrate intoa new organization
                          and impressyour new employer
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/changing-careers">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/CareerPaths.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/changing-careers"
                            class="side-nav-link-ref text-muted"
                            >Changing Careers</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Resourceful guidance on howto move forward when
                          youwant to change careers
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <router-link to="/leaving-a-job">
                    <div class="chhight_col1">
                      <img
                        src="images/carer_resorses/CareerPaths.png"
                        alt="Lights"
                        style="width: 100%"
                      />
                      <div class="captionch2 align-start">
                        <h5 class="mb-0">
                          <router-link
                            to="/leaving-a-job"
                            class="side-nav-link-ref text-muted"
                            >Leaving a job</router-link
                          >
                        </h5>
                        <p class="text-muted mb-0" style="font-size: 12px">
                          Learn about how to quit yourjob like a professional
                          andleave a good reputation behind
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="card my_card_imb">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>
                    <button type="submit" class="btn btn-default join_btn mb-6">
                      <a href="/job-seeker-register">Join job4u.pk now! </a>
                    </button>
                  </div>
                  <div class="col-lg-6 col-2 cv_image_bg">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5 card_fix_imb bg-light">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch bg-light"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch bg-light"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>

                  <!-- <select v-model="cities">
                    <option v-for="city in cities" v-bind:key="city.id"  v-bind:value="city.value">
                     {{ city.value }}
                    </option>
                  </select>

                   -->
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch bg-light"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--Search by Sectors-->
      <div
        class="home_section_two_ch"
        style="
          background: url(images/home/Stripes.png) no-repeat center 0px;
          background-size: cover;
        "
      >
        <div class="container py-8" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-3 col-12 text-center">
              <img
                class="img_two_sction_ch hide_on_mobile_ch"
                src="images/home/ViewbySectors.png"
                alt=""
                style="float: left"
              />
              <div class="col-lg-12 form_heading_home mb-10">
                View by Sectors
              </div>
            </div>
            <div class="col-lg-9 row pr-0">
              <div class="col">
                <div class="secter_box">
                  <img src="/images/home/IT&Software.png" alt="Lights" />
                  <div class="align-center">
                    <router-link
                      :to="'/jobs-careers-pakistan/jobs-sector/software--web-development'"
                      ><h1 class="secter_box_title">IT, Software & Web</h1>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="secter_box">
                  <img src="/images/home/Engineering.png" alt="Lights" />
                  <div class="align-center">
                    <router-link
                      :to="'/jobs-careers-pakistan/jobs-sector/Engineering'"
                      ><h1 class="secter_box_title">
                        Engineering
                      </h1></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="secter_box">
                  <img src="/images/home/Banking&Finance.png" alt="Lights" />
                  <div class="align-center">
                    <router-link
                      :to="'/jobs-careers-pakistan/jobs-sector/Engineering'"
                      ><h1 class="secter_box_title">
                        Banking & Finance
                      </h1></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="secter_box">
                  <img src="/images/home/Sales&BD.png" alt="Lights" />
                  <div class="align-center">
                    <router-link
                      :to="'/jobs-careers-pakistan/jobs-sector/Engineering'"
                      ><h1 class="secter_box_title">
                        Marketing & BD
                      </h1></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="secter_box">
                  <img src="/images/home/Creative&Design.png" alt="Lights" />
                  <div class="align-center">
                    <router-link
                      :to="'/jobs-careers-pakistan/jobs-sector/creative-design'"
                      ><h1 class="secter_box_title">
                        Creative & Design
                      </h1></router-link
                    >
                  </div>
                </div>
              </div>
              <router-link
                class="col home_section_two_last_box"
                :to="'/job-sectors'"
              >
                <!-- <div
                  
                ></div> -->
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
